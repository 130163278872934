import React from 'react'
import { useScrollPosition } from '@n8tb1t/use-scroll-position'

import '../assets/scss/main.scss'
import '../assets/css/CardStacks.scss'
import pic01 from '../assets/images/pic01.jpg'

export function CardStacks() {
  const [collapsed, setcollapsed] = React.useState(true)
  let myElem = React.useRef()

  function expandCards() {
    setcollapsed(!collapsed)
  }
  useScrollPosition(({ prevPos, currPos }) => {
    if (myElem) {
      if (myElem.getBoundingClientRect().top <= (2 * window.innerHeight) / 3) {
        setcollapsed(false)
      } else {
        setcollapsed(true)
      }
    }
  })

  const isBrowser = typeof window !== `undefined`

  function getScrollPosition({ element, useWindow }) {
    if (!isBrowser) return { x: 0, y: 0 }

    const target = element ? element.current : document.body
    const position = target.getBoundingClientRect()

    return useWindow
      ? { x: window.scrollX, y: window.scrollY }
      : { x: position.left, y: position.top }
  }

  return (
    <div
      ref={el => {
        if (el) {
          myElem = el
        }
      }}
      onClick={() => {
        expandCards()
      }}
      class={collapsed ? 'cards collapsed' : 'cards'}
    >
      <div class="stack">
        <div class="card card-1">
          <div class="contents">
            <h2>Your Code</h2>
          </div>
        </div>
        <div class="card card-2">
          <div class="contents">
            <h2>nader</h2>
          </div>
          <div class="attribute">
            <div class="close"></div>
            <div class="close-line">
              <p style={{ color: '#333', marginLeft: '5px', width: '150%' }}>
                Cloud Provider
              </p>
            </div>
          </div>
        </div>
        <div class="card card-3">
          <div class="contents">
            <h2>nader</h2>
          </div>
          <div class="attribute">
            <div class="close"></div>
            <div class="close-line">
              <p style={{ color: '#333', marginLeft: '5px', width: '150%' }}>
                Framework
              </p>
            </div>
          </div>
        </div>
        <div class="card card-4">
          <div class="contents">
            <h2>nader</h2>
          </div>
          <div class="attribute">
            <div class="close"></div>
            <div class="close-line">
              <p style={{ color: '#333', marginLeft: '5px', width: '150%' }}>
                Dependencies
              </p>
            </div>
          </div>
        </div>
        <div class="card card-5">
          <div class="contents">
            <h2>nader</h2>
          </div>
          <div class="attribute">
            <div class="close"></div>
            <div class="close-line">
              <p style={{ color: '#333', marginLeft: '5px' }}>
                Environment/Docker
              </p>
            </div>
          </div>
        </div>
        <div class="card shadow"></div>
      </div>
    </div>
  )
}

export function SingleStack() {
  return (
    <div class="cards">
      <div class="stack">
        <div class="card card-1">
          <div class="contents">
            <h2>Your Code</h2>
          </div>
        </div>
        <div class="card shadow"></div>
      </div>
    </div>
  )
}
