import React, { useEffect, useLayoutEffect, useState } from 'react'
import Grid, { GridSpacing } from '@material-ui/core/Grid'
import Hidden from '@material-ui/core/Hidden'
import { Link } from 'gatsby'

function useWindowPosition() {
  const [scrollPosition, setPosition] = useState(0)
  useLayoutEffect(() => {
    function updatePosition() {
      setPosition(window.pageYOffset)
    }
    window.addEventListener('scroll', updatePosition)
    updatePosition()
    return () => window.removeEventListener('scroll', updatePosition)
  }, [])
  return scrollPosition
}

//something is wrong with the layout in Header. Not sure why. This is an attempt to fix it.
function NavBar() {
  const val = useWindowPosition()
  const [showMenu, setShowMenu] = React.useState(false)

  return (
    <nav className={val > 0 ? 'header scrolled' : 'header'}>
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
      >
        <Hidden smDown>
          <Grid className="left">
            <p className="item">
              <Link to="/">Brev</Link>
            </p>
          </Grid>
          <Grid className="right">
            <Grid
              container
              direction="row"
              justify="flex-end"
              alignItems="center"
            >
              <p className="item">
                <a href="http://docs.brev.dev">documentation</a>
              </p>
              <p className="item">
                <Link to="/BlogHome">blog</Link>
                {/* <a href="/BlogHome">blog</a>  */}
              </p>
              <p className="item">
                <a href="https://app.brev.dev">login</a>
              </p>
            </Grid>
          </Grid>
        </Hidden>
        <Hidden mdUp>
          <div
            id="menuToggle"
            onClick={() => {
              setShowMenu(!showMenu)
            }}
            className={showMenu ? 'checked' : ''}
          >
            <input
              // onChange={event => {
              //   setShowMenu(event.target.checked)
              // }}
              type="checkbox"
            ></input>
            <span className={'hamburger'}></span>
            <span className={'hamburger'}></span>
            <span className={'hamburger'}></span>
          </div>
        </Hidden>
      </Grid>
      <div className={showMenu ? 'smallNavMenu' : 'hidden'}>
        <Grid
          container
          justify="center"
          alignItems="center"
          direction="column"
          style={{ paddingTop: '200px' }}
        >
          <p className="item">
            <a href="/">Brev</a>
          </p>
          <p className="item">
            <a href="http://docs.brev.dev">documentation</a>
          </p>
          <p className="item">
            <a href="/BlogHome">blog</a>
          </p>
          <p className="item">
            <a href="https://app.brev.dev">login</a>
          </p>
        </Grid>
      </div>
    </nav>
  )
}

export default NavBar
